@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&family=Lato:wght@700&display=swap);
nav {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #064a49;
}

.nav-link {
  margin: 5px 8px;
  text-decoration: none;
  color: white;
}

.navbar-links {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.nav-logo {
  max-width: 30%;
}

footer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #064a49;
  color: white;
  height: 2.5rem;
  width: 100%;
  overflow: hidden;
}

.icon {
  color: white;
  margin-left: 15px;
}

.landing {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: 500px;
}

h1 {
  font-size: 45px;
  font-weight: bold;
}

h1,
h2 {
  margin: 20px;
}

h3 {
  margin: 20px 0px;
}

p {
  margin: 15px;
}

.about {
  border-color: black;
  border-style: dashed;
  border-radius: 15px;
  margin: 15px;
}

.register {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  max-width: 500px;
}

.howto-register {
  border-color: black;
  border-style: dashed;
  border-radius: 15px;
  margin: 15px;
  margin-bottom: 50px;
}

.register-button {
    padding: 5px;
}

/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  /* Transparent Overlay */
  .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
  }
  
  /* :not(:required) hides these rules from IE9 and below */
  .loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  
  .loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  }
  
  /* Animation */
  
  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  /* https://codepen.io/MattIn4D/pen/LiKFC */

.Home {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    max-width: 500px;
}

.home-section {
border-color: black;
border-style: dashed;
border-radius: 15px;
margin: 15px;
}


.Map {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 20px;
  height: 100%;
  width: 100%;
}

.map-and-sidebar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
  left: 0px;
  bottom: 0;
  top: 500px;
  height: 100%;
  width: 100%;
}

.search {
  position: absolute;
  top: 1rem;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  width: 100%;
  max-width: 300px;
  z-index: 10;
}

.search input {
  padding: 0.5rem;
  font-size: 1.5rem;
  width: 100%;
}

.locate {
  top: 1rem;
  right: 1rem;
  z-index: 10;
  cursor: pointer;
  margin: 20px;
  padding:5px;
}


.sidebar {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;;
    width: 20vw;
    height: 70vh;
    background-color: #034a4a;
    overflow: scroll;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: white;
}

.sidebar-ul {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content:flex-start;
            justify-content:flex-start;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    list-style: none;
    padding: 0;
    margin:0;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    overflow: scroll;
}

.sidebar-markers {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 5px;
    padding: 5px;
    border: 2px;
    color: white;
    border-radius: 10px;
    border-style: solid;
    border-color: #e97966;
    margin: 10px;
    cursor: pointer;
    font-size: 12px;
}
.AddLocation {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  max-width: 500px;
}

.add-location-about {
  border-color: black;
  border-style: dashed;
  border-radius: 15px;
  margin: 15px;
  padding: 10px;
}

input {
  margin-bottom: 20px;
}

.addList {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.addList li {
  text-align: left;
  list-style-position: inside;
  list-style-type: disc;
  margin-bottom: 10px;
}

.submit {
  width: 100px;
  height: 50px;
  margin-bottom: 30px;
  margin-top: 30px;
}

* {
    box-sizing: border-box;
    margin: 0;
    list-style: none;
    padding: 0;
    text-align: center;
    /* font-family: Verdana; */
  }

  body {
    background-color: bisque;
  }

  main {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-family: 'Lato', sans-serif;
  }

  h1 {
    font-family: 'Fredoka One', cursive;
  }

 .App {
  position: relative;
  min-height: 100vh;
  padding-bottom: 2.5rem;
 }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-height: 100vh;
}

