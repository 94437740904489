.Home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 500px;
}

.home-section {
border-color: black;
border-style: dashed;
border-radius: 15px;
margin: 15px;
}

