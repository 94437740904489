.Map {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  height: 100%;
  width: 100%;
}

.map-and-sidebar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  left: 0px;
  bottom: 0;
  top: 500px;
  height: 100%;
  width: 100%;
}

.search {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  max-width: 300px;
  z-index: 10;
}

.search input {
  padding: 0.5rem;
  font-size: 1.5rem;
  width: 100%;
}

.locate {
  top: 1rem;
  right: 1rem;
  z-index: 10;
  cursor: pointer;
  margin: 20px;
  padding:5px;
}

