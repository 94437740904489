.sidebar {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;;
    width: 20vw;
    height: 70vh;
    background-color: #034a4a;
    overflow: scroll;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: white;
}

.sidebar-ul {
    display: flex;
    justify-content:flex-start;
    align-items: center;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin:0;
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    overflow: scroll;
}

.sidebar-markers {
    display: flex;
    flex-direction: column;
    margin: 5px;
    padding: 5px;
    border: 2px;
    color: white;
    border-radius: 10px;
    border-style: solid;
    border-color: #e97966;
    margin: 10px;
    cursor: pointer;
    font-size: 12px;
}