nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #064a49;
}

.nav-link {
  margin: 5px 8px;
  text-decoration: none;
  color: white;
}

.navbar-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.nav-logo {
  max-width: 30%;
}
