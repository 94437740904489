@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Lato:wght@700&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    list-style: none;
    padding: 0;
    text-align: center;
    /* font-family: Verdana; */
  }

  body {
    background-color: bisque;
  }

  main {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Lato', sans-serif;
  }

  h1 {
    font-family: 'Fredoka One', cursive;
  }

 .App {
  position: relative;
  min-height: 100vh;
  padding-bottom: 2.5rem;
 }